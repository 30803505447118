// src/App.js

import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import './App.css';
import logo from './logo.svg';
import Background from './img/pexels-nick-rtr-3187173.jpg';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Link from '@mui/material/Link';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://myunis.de/">
                myunis.de
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const App = () => {
    const getThemeModeFromLocalStorage = () => {
        const storedThemeMode = localStorage.getItem('themeMode');
        return storedThemeMode ? JSON.parse(storedThemeMode) : true; // По умолчанию темный режим (если режим не найден)
    };

    const [toggleDarkMode, setToggleDarkMode] = useState(getThemeModeFromLocalStorage);

    // function to toggle the dark mode as true or false
    const toggleDarkTheme = () => {
        const newMode = !toggleDarkMode;
        setToggleDarkMode(newMode);
        localStorage.setItem('themeMode', JSON.stringify(newMode));
    };

    const defaultTheme = createTheme({
        palette: {
            mode: toggleDarkMode ? 'dark' : 'light', // handle the dark mode state on toggle
        },
    });

    const [users, setUsers] = useState(() => {
        // Попытка получить данные из localStorage при загрузке компонента
        const storedData = localStorage.getItem('russianPokerData');
        return storedData ? JSON.parse(storedData) : [
            // Пример начальных данных
            { id: 1, name: 'Юнис', scores: Array(14).fill({ bet: 0, points: '' }) },
            { id: 2, name: 'Илья', scores: Array(14).fill({ bet: 0, points: '' }) },
            { id: 3, name: 'Денис', scores: Array(14).fill({ bet: 0, points: '' }) },
            { id: 4, name: 'Бублик', scores: Array(14).fill({ bet: 0, points: '' }) },
        ];
    });

    const handleEditUserName = (userId, newName) => {
        setUsers(users.map(user => (user.id === userId ? { ...user, name: newName || user.name } : user)));
    };

    const handleEditScore = (userId, roundIndex, field, value) => {
        setUsers(users.map(user => (user.id === userId ? {
            ...user,
            scores: user.scores.map((score, index) => (
                index === roundIndex ? { ...score, [field]: value } : score
            )),
        } : user)));
    };

    const handleAddUser = () => {
        const newUser = {
            id: users.length + 1,
            name: 'Новый игрок',
            scores: Array(14).fill({ bet: 0, points: '' }),
        };
        setUsers([...users, newUser]);
    };

    const handleRemoveUser = (userId) => {
        setUsers(users.filter(user => user.id !== userId));
    };

    const calculateTotalPoints = scores => scores.reduce((sum, score) => sum + score.points, 0);

    // Сохранение данных в localStorage при изменении users
    useEffect(() => {
        localStorage.setItem('russianPokerData', JSON.stringify(users));
    }, [users]);

    const handleResetData = () => {
        localStorage.removeItem('russianPokerData');
        // Сбросить состояние на начальное
        setUsers([
            { id: 1, name: 'Юнис', scores: Array(14).fill({ bet: 0, points: '' }) },
            { id: 2, name: 'Илья', scores: Array(14).fill({ bet: 0, points: '' }) },
            { id: 3, name: 'Денис', scores: Array(14).fill({ bet: 0, points: '' }) },
            { id: 4, name: 'Бублик', scores: Array(14).fill({ bet: 0, points: '' }) },
        ]);
    };

    const themeUse = useTheme()
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = RoundNames.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: "url(" + Background + ")",
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            py: 8,
                            px: 4,
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <div className="moreBtn">
                            <IconButton
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleResetData}>Сбросить данные</MenuItem>
                                <MenuItem onClick={handleAddUser}>Добавить пользователя</MenuItem>
                            </Menu>
                        </div>
                        <div className="darkMode">
                            <IconButton sx={{ ml: 1 }} onClick={toggleDarkTheme} color="inherit">
                                {defaultTheme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                        </div>
                        <div className="App">
                            <Typography className="logo" sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }} variant="h4" component="h3">
                                Russian Poker
                                <div className="App-logo"><img src={logo} alt="logo" /></div>
                            </Typography>
                            <Paper variant="outlined" sx={{maxWidth: 475, mx: 'auto', p: .5}}>
                                {RoundNames.map((round, roundIndex) => (
                                    <Box key={roundIndex} sx={{ display: activeStep === roundIndex ? 'block' : 'none' }}>
                                        <Box sx={{ maxWidth: 475, flexGrow: 1 }}>
                                            <Paper
                                                square
                                                elevation={0}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: 30,
                                                    pl: 2,
                                                    bgcolor: 'background.default',
                                                }}
                                            >
                                                <Typography>Раунд - {round}</Typography>
                                            </Paper>
                                            <Box sx={{height: '100%', maxWidth: 475, width: '100%', p: 1 }}>
                                                {users.map((user, userIndex) => (
                                                    <Card variant="outlined" className='userCard' key={userIndex} sx={{minWidth: 275 }}>
                                                        <CardContent sx={{p: '6px 10px!important'}}>
                                                            <div className="userNameWrap">
                                                                <h3>
                                                                    <b>{user.name} </b>
                                                                    <Badge color="primary" max={999} badgeContent={calculateTotalPoints(user.scores)}>
                                                                        <ScoreboardIcon/>
                                                                    </Badge>
                                                                </h3>
                                                                <div>
                                                                    <IconButton aria-label="edit" onClick={() => handleEditUserName(user.id, prompt('Введите новое имя', user.name))}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="delete" onClick={() => handleRemoveUser(user.id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                            <div style={{display: 'flex', flexFlow:'row', flexWrap:'nowrap'}}>
                                                                <FormControl variant="filled" sx={{ m: 1, minWidth: 100 }}>
                                                                    <InputLabel id={`select-filled-bet-label-${userIndex}`}>Ставка</InputLabel>
                                                                    <Select
                                                                        labelId={`select-filled-bet-label-${userIndex}`}
                                                                        id={`select-filled-bet-${userIndex}`}
                                                                        value={user.scores[roundIndex].bet}
                                                                        onChange={e => handleEditScore(user.id, roundIndex, 'bet', +e.target.value)}
                                                                    >
                                                                        {[0, 1, 2, 3, 4, 5].map(value => (
                                                                            <MenuItem key={value} value={value}>
                                                                                {value}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                <TextField type="number" label="Очки" variant="filled"
                                                                           sx={{ m: 1, minWidth: 100, width: '100%' }}
                                                                           value={user.scores[roundIndex].points}
                                                                           onChange={e => handleEditScore(user.id, roundIndex, 'points', +e.target.value)}
                                                                />
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                ))}
                                            </Box>
                                            <MobileStepper
                                                sx={{p: '0'}}
                                                variant="text"
                                                steps={maxSteps}
                                                position="static"
                                                activeStep={activeStep}
                                                nextButton={
                                                    <Button
                                                        size="small"
                                                        onClick={handleNext}
                                                        disabled={activeStep === maxSteps - 1}
                                                    >
                                                        Вперед
                                                        {themeUse.direction === 'rtl' ? (
                                                            <KeyboardArrowLeft />
                                                        ) : (
                                                            <KeyboardArrowRight />
                                                        )}
                                                    </Button>
                                                }
                                                backButton={
                                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                                        {themeUse.direction === 'rtl' ? (
                                                            <KeyboardArrowRight />
                                                        ) : (
                                                            <KeyboardArrowLeft />
                                                        )}
                                                        Назад
                                                    </Button>
                                                }
                                            />
                                        </Box>
                                    </Box>
                                ))}
                            </Paper>
                            <Copyright sx={{ mt: 2 }} />
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

const RoundNames = [
    '1', '2', '3', '4', '5', '4', '3', '2', '1', 'Без козыря', 'Не смотрим', 'Смотрим у других', 'Двойные очки', 'БК-НС-ДО'
];

export default App;
